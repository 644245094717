import { authHeader } from "../helpers/auth_helper";
import config from "../config";
import axios from "axios";

const getPagingRunsRequest = async (deployment, params) => {
  const url = `${ config.adminURL }/reconciliation/${ deployment }/pagingRuns`;
  
  return await axios.get(url,{
    params,
    headers: await authHeader(),
  });
}

const getPagingReconciliationRequest = async (deployment, params) => {
  const url = `${ config.adminURL }/reconciliation/${ deployment }/pagingReconciliation`;

  return await axios.get(url,{
    params,
    headers: await authHeader(),
  });
}

const getSettingRequest = async (deployment) => {
  const headers = await authHeader();
  const options = {
    method: 'GET',
    headers,
    credentials: 'include',
  };

  const url = `${ config.adminURL }/reconciliation/${ deployment }`;
  const result = await fetch(
      url,
      options
  );
  if (result.ok === false) {
    throw new Error(result.statusText);
  }

  return await result.json();
}

const updateSettingRequest = async (deployment, data) => {
  const headers = await authHeader();
  const options = {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify(data)
  };

  const url = `${ config.adminURL }/reconciliation/${deployment}/updateSetting`;
  const result = await fetch(
      url,
      options
  );
  if (result.ok === false) {
    throw new Error(result.statusText);
  }

  return await result.json();
}

export const reconciliationService = {
  getPagingRunsRequest,
  getPagingReconciliationRequest,
  getSettingRequest,
  updateSettingRequest
};
