import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { Col, Form, Row, FormGroup, Label, Card, CardTitle, CardHeader, CardBody, Button, Spinner } from "reactstrap"
import { reconciliationService } from "../../../services/reconciliation-service";
import ReconciliationDetail from "./ReconciliationDetail";
import CustomPagination from "../../../components/Common/CustomPagination";
import { Context as SettingsContext } from "../../../context/DeploymentSettingsContext";


const RunsTab = ({ active }) => {
  const [ itemCount, setItemCount ] = useState(0)
  const [ pageCount, setPageCount ] = useState(0)
  const [ page, setPage ] = useState(1);
  const [ pageSize, setPageSize ] = useState(10)
  
  // Row Data: The data to be displayed.
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [ gridFirstDataRendered, setGridFirstDataRendered ] = useState(false);
  const [ loading, setLoading ] = useState(false)
  const [ runId, setRunId ] = useState(null);
  const [ options, setOptions] = useState([]);
  const [ deployment, setDeployment ] = useState(null)

  const viewButton = (props) => {
    const { data } = props
    return <Button size={ "sm" } color={ "primary" } onClick={ () => handleViewRun(data) }>View</Button>;
  };

  const handleViewRun = (data) => {
    setRunId(data.runId);
  }

  const [ rowData, setRowData ] = useState([]);

  const [ colDefs, setColDefs ] = useState([
    { field: "id" },
    { field: "runId" },
    { field: "batchSize" },
    { field: "createdDate" },
    { field: "updatedDate" },
    { field: "action", cellRenderer: viewButton },
  ]);

  const {
    state: settingsState,
    getBranchSettings,
  } = useContext(SettingsContext);
  
  const onFirstDataRendered = useCallback((params) => {
    setGridFirstDataRendered(true);
    if (active) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  useEffect(() => {
    if (active) {
      getBranchSettings();
    
    }
  }, [ active, page, pageSize ])

  useEffect(() => {
    
    if(settingsState.branchSettings.length > 0){
      let singleEnv = settingsState.branchSettings.find(a => a.name === 'single-environment');
      if(singleEnv){ 
        let data = singleEnv.deployments.map(deployment => ({
          key: deployment.name,
          value: deployment.name,
          label: deployment.name
        }));
        setOptions(data);
      }
    }
  }, [ settingsState.branchSettings ]);
  
  const handleSelectDeployment = (deployment) => {
    setRunId(null);
    setDeployment(deployment);
    
    setLoading(true);
    reconciliationService.getPagingRunsRequest(deployment.value, {
      page,
      pageSize
    }).then(res => {
      if (res.data) {
        const { data, itemCount, pageCount } = res.data;
        setRowData(data);
        setItemCount(itemCount);
        setPageCount(pageCount);
        setLoading(false);
      }
    }).catch(err => {
          console.log(err);
          setLoading(false);
        }
    )
  }
  
  return (
      <>
        <Card>
          <CardHeader className="bg-transparent border-bottom border-bottom-1">
            <CardTitle className="mb-0">Environments</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Form>
                  <FormGroup>
                    <Label>
                      single-environment:
                    </Label>
                    <Select
                        value={deployment}
                        name="deployment"
                        // isMulti
                        onChange={option => handleSelectDeployment(option)}
                        options={options}
                        // className="basic-multi-select"
                        // classNamePrefix="select"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 1,
                        })}
                    />
                  </FormGroup>
                </Form>
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="mb-2">
          <CardHeader className="bg-transparent border-bottom border-bottom-1">
            <CardTitle className="mb-0">Runs: {deployment?.value}</CardTitle>
          </CardHeader>
          <CardBody>
            { loading ?
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <Spinner
                      className="m-2"
                      color="primary"
                  >
                    Loading...
                  </Spinner>
                </div> : <div
                    className="ag-theme-quartz" // applying the Data Grid theme
                    style={ { height: 200 } } // the Data Grid will fill the size of the parent container
                >
                  <AgGridReact
                      ref={ gridRef } // Ref for accessing Grid's API
                      rowData={ rowData }
                      columnDefs={ colDefs }
                      onFirstDataRendered={ onFirstDataRendered }
                  />
                  <div className="mt-2">
                    <CustomPagination
                        itemCount={ itemCount }
                        currentPage={ page }
                        totalPage={ pageCount }
                        defaultPageSize={ pageSize }
                        pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                        onChangePage={ i => setPage(i) }
                        onPageSizeChange={ size => {
                          setPage(1);
                          setPageSize(size);
                        } }
                    />
                  </div>
                </div> }

          </CardBody>
        </Card>
        {
            runId &&
            <ReconciliationDetail runId={ runId } deployment={deployment?.value}/>
        }
      </>
  );
};

RunsTab.propTypes = {};

export default RunsTab;